// @ts-nocheck
import gql from "graphql-tag";
// import buildGraphQLProvider from 'ra-data-graphql'
import _ from "lodash";
import { raSubgraphTranslator } from "./utils";

const pluralize = require("pluralize");
// const myj = require("./pandifi-v1-core-subtree/deployments/ropsten/Digitizer.json");

const networkName = "ropsten";

const networkLink = {
  ropsten: {
    owner: "https://ropsten.etherscan.io/address/",
    token: "https://ropsten.etherscan.io/address/",
    pool: "https://app.uniswap.org/#/swap?inputCurrency=",
  },
  mainnet: {
    owner: "https://etherscan.io/address/",
    token: "https://info.uniswap.org/#/tokens/",
    pool: "https://info.uniswap.org/#/pools/",
  },
};

// const poolField = `eligibleHomogenizers (where:{isEligible:true}) {
//   homogenizer {
//     token {
//       id
//       symbol
//       name
//       whitelistPools {
//         id
//         token0Price
//         token0{
//           id
//           symbol
//           name
//         }
//         token1Price
//         token1 {
//           id
//           symbol
//           name
//         }
//         feeTier
//       }
//     }
//   }
// }`;

// const hoistData = (item) => {
//   item.hoisted = item.eligibleHomogenizers.map((x) => {
//     return x.homogenizer.token;
//   });
//   item.hoisted = item.hoisted.map((homogenizer) => {
//     homogenizer.whitelistPools = homogenizer.whitelistPools.map((pool) => {
//       pool.price =
//         homogenizer.id == pool.token0.id ? pool.token1Price : pool.token0Price;
//       pool.stablecoinId =
//         homogenizer.id == pool.token0.id ? pool.token1.id : pool.token0.id;
//       return pool;
//     });
//     homogenizer.whitelistPools.sort((a, b) => b.price - a.price);
//     homogenizer.bestPrice = homogenizer.whitelistPools[0].price;
//     homogenizer.bestPoolId = homogenizer.whitelistPools[0].id;
//     homogenizer.bestPoolStablecoinId =
//       homogenizer.whitelistPools[0].stablecoinId;
//     homogenizer.bestPoolFeeTier = homogenizer.whitelistPools[0].feeTier;
//     return homogenizer;
//   });
//   item.hoisted.sort((a, b) => b.bestPrice - a.bestPrice);
//   item.bestHomogenizerId = item.hoisted[0].id;
//   item.bestHomogenizerSymbol = item.hoisted[0].symbol;
//   // item.bestHomogenizerSymbolLink =
//   //   networkLink[networkName].token + item.bestHomogenizerId;
//   item.bestPrice = item.hoisted[0].bestPrice;
//   item.bestPoolId = item.hoisted[0].bestPoolId;
//   item.bestPoolStablecoinId = item.hoisted[0].bestPoolStablecoinId;
//   item.bestPoolFeeTier = item.hoisted[0].bestPoolFeeTier;
//   item.bestHomogenizerSymbolLink =
//     networkName == "ropsten"
//       ? networkLink[networkName].pool +
//         item.bestHomogenizerId +
//         "&outputCurrency=" +
//         item.bestPoolStablecoinId
//       : networkLink[networkName].token + item.bestHomogenizerId;
//   item.bestPriceLink =
//     networkName == "ropsten"
//       ? `https://app.uniswap.org/#/add/${item.bestHomogenizerId}/${item.bestPoolStablecoinId}/${item.bestPoolFeeTier}`
//       : networkLink[networkName].pool + item.bestPoolId;
//   //   networkName == "ropsten"
//   //     ? networkLink[networkName].pool +
//   //       item.bestHomogenizerId +
//   //       "&outputCurrency=" +
//   //       item.bestPoolStablecoinId
//   //     : networkLink[networkName].pool + item.bestPoolId;
//   item.ownerId = item.owner.id;
//   item.ownerLink = networkLink[networkName].owner + item.ownerId;
//   return item;
// };

export function fetchHomogenizers(
  params: any,
  resourceName: string,
  fields: string[]
) {
  // console.log(fields);
  let queryString = `query {
    erc20Contracts(first: ${params.pagination.perPage}, skip: ${
    params.pagination.perPage * (params.pagination.page - 1)
  }, orderBy: ${
    params.sort.field
  }, orderDirection: ${params.sort.order.toLowerCase()}, where: ${JSON.stringify(
    params.filter
  ).replaceAll('"', "")}) {
    id
    name
    symbol
    decimals
    totalSupply {
      value
    }
    balances {
      id 
      value
    }
    }
    
  }
  `;
  // console.log(queryString);

  return {
    query: gql`
      ${queryString}
    `,
    variables: params, // params = { id: ... }
    parseResponse: (response) => {
      // console.log("resourceName", resourceName);
      // console.log(response)
      let responseData =
        response.data[
          pluralize(_.camelCase(raSubgraphTranslator[resourceName]))
        ];
      // console.log("responseData; ", responseData);
      // console.log("params.pagination.perPage; ", params.pagination.perPage);
      responseData = responseData.map((x) => x); // hoist function
      responseData = responseData.map((item) => {
        let accountAddress = item.balances.map((entry)=>{
          if(entry.account!=null){
            let accountName = entry.account.id
            item[accountName] = entry.value
          }
        })
        item["identifier"] = item.id;
        item["notional"] = item.totalSupply.value * 100;
        // item["totalSupply"] = item.totalSupply.value;
        return item;
      });
      // console.log("responseData; ", responseData);
      return {
        data: responseData,
        // total: params.pagination.perPage,
        total: "30",
      };
    },
  };
}

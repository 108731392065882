// See: https://marmelab.com/react-admin/List.html#bulkactionbuttons
// in ./CustomResetViewsButton.js

//Note:  Temporarily hardcoded for homogenizer
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { Fragment, useState, useEffect, useContext } from "react";
import {
  Button,
  Confirm,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { toBigNumber, getConnectedAccount } from "../utils";
import { useGetMany } from "react-admin";
import usePandiFiContracts from "../hooks/usePandiFiContracts";

import { BigNumber, Contract, Signer } from "ethers";
import { LoanList } from "../pages/LoanList";
import { Digitizer } from "../typechain/Digitizer";
import {
  HomogenizerFactoryClone as HomogenizerFactory,
  HomogenizerFactoryClone,
} from "../typechain/HomogenizerFactoryClone";
import { HomogenizerUpgradable as Homogenizer } from "../typechain/HomogenizerUpgradable";
import { IERC20 } from "../typechain/IERC20";
import { ContractInterface } from "@ethersproject/contracts";
import { array } from "prop-types";
import { useActiveWeb3React } from "../utils";
import { waitFor } from "@testing-library/dom";
import { PendingContext } from "../PendingContext";
import { confirmationTable } from "../ra-utils";

//to-do: check for sufficient stablecoin prior to allowing confirm
async function homogenizeBatch(
  loanList: Record<string, any>[],
  signer: Signer,
  digitizer: Digitizer,
  homogenizerFactory: HomogenizerFactoryClone,
  stablecoin: IERC20,
  homogenizerAbi: ContractInterface
) {
  const signerAddress: string = await signer.getAddress();

  //why is this undefined sometimes?
  if (loanList) {
    //to-do: create processForBatchOperation util function which runs lines 102-125 for both homgenization and dehomogenization
    //and takes usePandiFiContracts parameters as inputs to improve testing
    loanList = loanList.map((loan: Record<string, any>) => {
      //We first add an object field to define the homogenizer being used. 
      //Question: do we need this or can we call bestHomogenizerId below?
      loan["homogenizer"] = new Contract(
        loan.bestHomogenizerId,
        homogenizerAbi,
        signer
      ) as Homogenizer;
      return loan;
    });
    //Create an array of all tokenIds to be homogenized
    let tokenIds: Array<string> = loanList.map((loan) => loan.identifier);
    //Create a corresponding array of all homogeizer addresses for token homogenization
    let homogenizerAddresses: Array<string> = loanList.map(
      (loan) => loan.homogenizer.address
    );

    let stablecoinRequired = 0;
    let homogenizerContract;
    let cashRequired
    for (let i = 0; i < tokenIds.length; i++) {
      homogenizerContract = new Contract(homogenizerAddresses[i], homogenizerAbi, signer)
      cashRequired = Number(await homogenizerContract.currentCashRequired(tokenIds[i]))
      stablecoinRequired += cashRequired;
    }
    const mockUsdcBalance = Number(await stablecoin.balanceOf(signerAddress));
    if (mockUsdcBalance < stablecoinRequired) {
      alert("Insufficient USDC Balance to homogenize selected loans.  Please use our faucet to get Mock USDC")
      window.location.reload();
      return
    }
    //Build a set of all unique homogenizer addresses and then turn it into an array
    let uniqueHomogenizerAddresses: Set<any> = new Set(homogenizerAddresses);
    let uniqueHomogenizerArray = Array.from(uniqueHomogenizerAddresses);

    let setApprovalForAll;
    let completedDigitizerApproval;
    let stablecoinAllowance;
    let setStablecoinAllowance;
    let completedStableCoinApproval;

    //t-do: Make sure LLPA/buyDown stats are at the highest subgraph level
    //to-do: Move this function to util folder?
    //Approval checks should confirm homogenizer has permission to move digitizer erc721 tokens AND stablecoin erc20 tokens
    let approvalChecks = uniqueHomogenizerArray.map(
      //first, we check to see if each homogenizer has permission to transfer
      //digitizer ERC721 tokens on the the signer's behalf.
      async (homogenizerAddress: string) => {
        let digitizerApproval = await digitizer.isApprovedForAll(
          signerAddress,
          homogenizerAddress
        );
        // console.log(digitizerApproval);
        if (digitizerApproval == false) {
          try {
            setApprovalForAll = await digitizer
              .connect(signer)
              .setApprovalForAll(homogenizerAddress, true);
            completedDigitizerApproval = await setApprovalForAll.wait();
          } catch (error) {
            window.location.reload();
          }
        }
        //Test if homogenizer has permission to transfer ERC20 stablecoin tokens on signers behalf
        stablecoinAllowance = Number(
          await stablecoin.allowance(signerAddress, homogenizerAddress)
        );
        //If homogenizer's stablecoin allowance is insufficient, set it very high
        if (stablecoinAllowance < 10 ** 15) {
          try {
            let amount = BigNumber.from("10000000000000000000000000000000000");
            setStablecoinAllowance = await stablecoin
              .connect(signer)
              .approve(homogenizerAddress, amount);
            completedStableCoinApproval = await setStablecoinAllowance.wait();
          } catch (error) {
            window.location.reload();
          }

        }
      }
    );
    //Perform batch operation only after all async operations inside of the approvalChecks are complete
    Promise.all(approvalChecks).then(async () => {
      try {
        let batchOperation = await homogenizerFactory.homogenizeBatch(
          tokenIds,
          homogenizerAddresses,
          true
        );
        let batchCompletion = await batchOperation.wait();
        batchCompletion ? (window.location.href = "/") : console.log("not yet");
      } catch (error) {
        console.log(error)
        window.location.reload();
      }
    });
  }
}

const HomogenizeButton = ({ resource, selectedIds }: any) => {
  //@ts-ignore
  const { pendingStatus, setPendingStatus } = useContext(PendingContext);
  const { signer, digitizer, homogenizerFactory, stablecoin, homogenizerAbi } =
    usePandiFiContracts();
  // console.log(digitizer);
  const { account, library, chainId } = useActiveWeb3React();

  //useGetMany allows us to fetch the selected data from our datagrid
  const { data, loading, error } = useGetMany(resource, selectedIds);
  // console.log("data ", data);
  const [open, setOpen] = useState(false);
  // const refresh = useRefresh();
  // const notify = useNotify();
  // const unselectAll = useUnselectAll();

  const handleClick = () => {
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => { };

  return (
    <Fragment>
      <Button
        label="Homogenize Selected"
        icon={<MonetizationOnIcon />}
        onClick={handleClick}
      />
      <Confirm
        //@ts-ignore
        fullWidth
        maxWidth="xl"
        isOpen={open}
        loading={loading}
        title={
          "Confirm Homogenization: Please look through the data below to ensure you are homogenizing the correct NFTS"
        }
        content={data ? confirmationTable(data, chainId) : null}
        onConfirm={() => {
          setPendingStatus(true)
          homogenizeBatch(
            data,
            signer,
            digitizer,
            homogenizerFactory,
            stablecoin,
            homogenizerAbi
          );
          setOpen(false);
        }}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default HomogenizeButton;

//@ts-nocheck
//type errors in custom field components on 'label'

import { useStyles } from "../Styles";
import { List, Datagrid } from "react-admin";
import {
  loanExporter,
  PostBulkActionButtons,
  loanFilters,
  ExpandedData,
  QuickFilters
} from "../ra-utils";
import {
  IDTextField,
  PercentageField,
  DollarField,
  CurrentLiquidityPoolPriceField,
  BuyDownPercentageField,
  BestLoanPrice,
  OwnerField,
  BuyButton
} from "../components/CustomFields";
import { PendingContext } from "../PendingContext";
import LinearProgress from '@mui/material/LinearProgress';
import { useContext } from 'react'

export function HomogenizedLoans(props: any) {
  const { pendingStatus, setPendingStatus } = useContext(PendingContext);
  let propsWithBatchActions = Object.assign({}, props);
  propsWithBatchActions["batchAction"] = "dehomogenize";
  // console.log(propsWithBatchActions);
  const classes = useStyles();
  //to-do: Make sure useContext is compatible with multiple users on the application at once
  if (pendingStatus) {
    return (
      <div style={{ width: "75%", padding: "25px", margin: "0 auto 0 auto" }}>
        <h3>You will be redirected when all transactions clear. Please, check metamask for any pending confirmations and approvals...</h3>
        <LinearProgress />
      </div>
    )
  } else {
    return (
      <List
        title=" | Homogenized Loans"
        //@ts-ignore
        {...propsWithBatchActions}
        perPage={10}
        exporter={loanExporter}
        bulkActionButtons={
          //@ts-ignore
          <PostBulkActionButtons bulkAction={propsWithBatchActions.batchAction} />
        }
        aside={<QuickFilters />}
        sort={{ field: 'identifier', order: 'ASC' }}
        filters={loanFilters}
        filter={{ isOwnedByHomogenizer: true }}
      >
          <Datagrid classes={classes} expand={<ExpandedData />} optimized>
            <IDTextField sortBy="identifier" />
            <DollarField label="Current UPB" source="currentUPB" />
            <PercentageField source="originalRate" />
            {/* Current LP Pool Price derived in the front end and not sortable */}
            <CurrentLiquidityPoolPriceField sortable={false} />
            <PercentageField label="LLPA" source="llpaPercentage" />
            {/* Buydown derived in front end and cannot be sorted */}
            <BuyDownPercentageField sortable={false} label="Buydown %" source="buydownPercentage" />
            {/*Not sortable because price is calculated in the front end*/}
            <BestLoanPrice label="Best Loan Price" />
            {/* cannot be sorted because best homogenizer built in loan.ts file */}
            <OwnerField label="Homogenizer" sortable={false} />
            <BuyButton label="Buy Best Homogenizer Tokens" sortable={false} />
          </Datagrid>
      </List>
    );
  }
}

//@ts-nocheck
import { makeStyles } from "@material-ui/core/styles";

// import {
//   ApolloClient,
//   InMemoryCache,
//   ApolloProvider,
//   gql,
// } from "@apollo/client";

import { apiUrl } from "../constants";
import { sideCardInventory, fullCardInventory } from "../components/DashboardCards";
const useStyles = makeStyles((theme) => ({
  parent: {
    display: "flex",
  },
  side: {
    display: "flex",
    flexWrap: "wrap",
    width: "33%",
  },
  full: {
    width: "66^",
  },
  card: {
    borderRadius: "15px",
    background: "white",
    padding: "10px"
  }
}));

export default function Dashboard() {
  // const apiUrl = "https://api.thegraph.com/subgraphs/name/pandichef/hpni0";
  // const client = new ApolloClient({
  //   uri: apiUrl,
  //   cache: new InMemoryCache(),
  // });
  const classes = useStyles();

  return (
    // <ApolloProvider client={client}>
    <div className={classes.parent}>
      <div className={classes.side}>
          {sideCardInventory.map((card) => (
            <div>{card}</div>
          ))}
        </div>
      <div className={classes.full}>
          {fullCardInventory.map((card) => (
            <div className={classes.card}>{card}</div>
          ))}
        </div>
    </div>
    // </ApolloProvider>
  );
}

// @ts-nocheck
import { Fragment, useState, useEffect } from "react";
import "../App.css";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  UrlField,
  useRedirect,
} from "react-admin";
import { useStyles } from "../Styles";
import {
  DollarField,
  ActiveUserHomogenizerUPBDollarField,
  SellButton,
} from "../components/CustomFields";
import usePandiFiContracts from "../hooks/usePandiFiContracts";
import { homogenizerExporter } from "../ra-utils";

const loanFilters = [
  <TextInput label="Search" source="text" alwaysOn />,
  <TextInput
    label="Max Original UPB"
    source="originalUPB_lte"
    defaultValue=""
    alwaysOn
  />,
];

//should these variables be in useState?

//Custom CSS based on Datagrid api
//https://marmelab.com/react-admin/List.html#datagrid-css-api

export function HomogenizerList(props: any) {
  const redirect = useRedirect();
  // redirect("/homogenizedLoans", "/#");
  const classes = useStyles();
  const { signer } = usePandiFiContracts();
  let activeAddress = signer._address;
  return (
    <List
      title=" | All Homogenizers"
      // aside={<Aside />}
      {...props}
      exporter={homogenizerExporter}
      sort={{ field: "id", order: "ASC" }}
      pagination={false}
      //to-do: fix selected bulk export
      bulkActionButtons={false}
    >
      <Datagrid
        classes={classes}
        rowClick={(id, basePath, record) => {
          console.log(record);
          if (record.notional > 0)
            redirect(
              `/homogenizedLoans?filter=%7B"ownerId"%3A"${id}"%7D`,
              "/#"
            );
        }}
      >
        <UrlField source="id" />
        <TextField source="symbol" />
        <TextField source="name" />
        {/* Notional field pulled in not as a number, so it cannot be sorted */}
        <DollarField sortable={false} source="notional" />
        {/* Value not pulled form subgraph as a number and dervied further in UI */}
        <ActiveUserHomogenizerUPBDollarField
          source={activeAddress}
          label="My Balance"
          sortable={false}
        />
        <SellButton label="Sell Homogenizer Tokens" sortable={false} />
      </Datagrid>
    </List>
  );
}

//@ts-nocheck
import { Button } from "@material-ui/core"
import { useStyles } from "../Styles";
import { ethers } from "ethers";
import { PendingContext } from "../PendingContext";
import {useContext } from "react";
import usePandiFiContracts from "../hooks/usePandiFiContracts";
import { useActiveWeb3React } from "../utils";


const sendMockUsdc = async (
    stablecoin,
    recipient
) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    //TEMPORARY ADMIN PRIVATE KEY USED FOR ROPSTEN DEMO SITE
    const adminWallet = new ethers.Wallet('4b4dc16204ceba609bfddaaab4c89d6a16d004c818f3d9d778d7316304e79a9b', provider)
    let tx = await stablecoin.connect(adminWallet).transfer(recipient, "10000000000")
    let waitforit = await provider.waitForTransaction(tx.hash)
    window.location.reload();
    return tx
}

const SendUsdcButton = () => {
    const { pendingStatus, setPendingStatus } = useContext<any>(PendingContext);
    const { account, library, chainId } = useActiveWeb3React();
    const { stablecoin } = usePandiFiContracts()
    const classes = useStyles();
    const handleClickOpen = async () => {
        setPendingStatus(true);
        sendMockUsdc(stablecoin, account);
    }

    return (<Button variant="outlined" className={classes.button} onClick={handleClickOpen}>Send me $10k Mock USDC</Button>)
}

export default SendUsdcButton;
//@ts-nocheck
import { defaultTheme } from "react-admin";
import { createTheme } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import { Palette } from "@mui/icons-material";

//overrides temporary
export const myTheme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#000000",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
  overrides: {
    RaFilterForm: {
      '& .RaNumberInput': {
        backgroundColor: 'white',
      },
      '& .RaTextInput': {
        backgroundColor: 'white',
      },
      '& .RaSelectInput': {
        backgroundColor: 'white',
      }
    },
  },

});

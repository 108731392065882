// @ts-nocheck
import gql from "graphql-tag";
// import buildGraphQLProvider from 'ra-data-graphql'
import _ from "lodash";
import { raSubgraphTranslator } from "./utils";

const pluralize = require("pluralize");
// const myj = require("./pandifi-v1-core-subtree/deployments/ropsten/Digitizer.json");

const networkName = "ropsten";

const networkLink = {
  ropsten: {
    owner: "https://ropsten.etherscan.io/address/",
    token: "https://ropsten.etherscan.io/address/",
    pool: "https://app.uniswap.org/#/swap?inputCurrency=",
  },
  mainnet: {
    owner: "https://etherscan.io/address/",
    token: "https://info.uniswap.org/#/tokens/",
    pool: "https://info.uniswap.org/#/pools/",
  },
};

const poolField = `eligibleHomogenizers (where:{isEligible:true}) {
  buydownPercentage
  homogenizer {
    token {
      id
      symbol
      name
      whitelistPools {
        id
        token0Price
        token0{
          id
          symbol
          name
        }
        token1Price
        token1 {
          id
          symbol
          name
        }
        feeTier
      }
    }
  }
}`;

const hoistData = (item) => {
  item.hoisted = item.eligibleHomogenizers.map((x) => {
    let erc20 = x.homogenizer.token;
    erc20["buydownPercentage"] = x.buydownPercentage;
    return erc20;
  });
  item.hoisted = item.hoisted.map((homogenizer) => {
    homogenizer.whitelistPools = homogenizer.whitelistPools.map((pool) => {
      pool.price =
        homogenizer.id == pool.token0.id ? pool.token1Price : pool.token0Price;
      pool.stablecoinId =
        homogenizer.id == pool.token0.id ? pool.token1.id : pool.token0.id;
      return pool;
    });
    homogenizer.whitelistPools.sort((a, b) => b.price - a.price); // sort liquidity pools
    homogenizer.bestPrice = homogenizer.whitelistPools[0].price; // grab the best liquidity pool
    // homogenizer.buydownPercentage = item.buydownPercentage;
    homogenizer.bestPriceNet =
      homogenizer.bestPrice - homogenizer.buydownPercentage;
    homogenizer.bestPoolId = homogenizer.whitelistPools[0].id;
    homogenizer.bestPoolStablecoinId =
      homogenizer.whitelistPools[0].stablecoinId;
    homogenizer.bestPoolFeeTier = homogenizer.whitelistPools[0].feeTier;
    return homogenizer;
  });
  item.hoisted.sort((a, b) => b.bestPriceNet - a.bestPriceNet);
  item.bestHomogenizerId = item.hoisted[0].id;
  item.bestHomogenizerSymbol = item.hoisted[0].symbol;
  item.bestPrice = item.hoisted[0].bestPrice;
  item.buydownPercentage = item.hoisted[0].buydownPercentage;
  item.bestPriceNet = item.hoisted[0].bestPriceNet;
  item.bestLoanPrice = item.bestPriceNet - item.llpaPercentage;
  item.bestPoolId = item.hoisted[0].bestPoolId;
  item.bestPoolStablecoinId = item.hoisted[0].bestPoolStablecoinId;
  item.bestPoolFeeTier = item.hoisted[0].bestPoolFeeTier;
  item.bestHomogenizerSymbolLink =
    networkName == "ropsten"
      ? networkLink[networkName].pool +
      item.bestHomogenizerId +
      "&outputCurrency=" +
      item.bestPoolStablecoinId
      : networkLink[networkName].token + item.bestHomogenizerId;
  item.bestPriceLink =
    networkName == "ropsten"
      ? `https://app.uniswap.org/#/add/${item.bestHomogenizerId}/${item.bestPoolStablecoinId}/${item.bestPoolFeeTier}`
      : networkLink[networkName].pool + item.bestPoolId;
  item.ownerId = item.owner.id;
  item.ownerLink = networkLink[networkName].owner + item.ownerId;
  return item;
};

export function fetchLoans(params, resourceName, fields) {

    // See https://stackoverflow.com/questions/11233498/json-stringify-without-quotes-on-properties
    // let queryString = `query {
    //   erc721Contracts{
    //     tokens{
          
    //     }
    //   }


    //   erc721Tokens(orderBy: ${params.sort.field
    // }, orderDirection: ${params.sort.order.toLowerCase()}, where: ${JSON.stringify(
    //   params.filter
    // ).replace(/"([^"]+)":/g, "$1:")}) {
    //     ${fields.join("\n")}
    //   }
    // }
    // `
    // .replace("eligibleHomogenizers { id }", poolField)
    // .replace("isOwnedByHomogenizer { id }", "isOwnedByHomogenizer");

    // // See https://stackoverflow.com/questions/11233498/json-stringify-without-quotes-on-properties
    let queryString = `query {
      erc721Tokens(first: ${params.pagination.perPage}, skip: ${params.pagination.perPage * (params.pagination.page - 1)
    }, orderBy: ${params.sort.field
    }, orderDirection: ${params.sort.order.toLowerCase()}, where: ${JSON.stringify(
      params.filter
    ).replace(/"([^"]+)":/g, "$1:")}) {
        ${fields.join("\n")}
      }
    }
    `
    .replace("eligibleHomogenizers { id }", poolField)
    .replace("isOwnedByHomogenizer { id }", "isOwnedByHomogenizer");
  
// CURRENTLY, PAGINATION IS NOT POSSIBLE SINCE THE TOTAL UNPAGINATED IS NOT RETRIEVED FROM THE GRAPHQL QUERY
  // const provider = new ethers.providers.Web3Provider(window.ethereum);
  // const digitizer = new Contract(
  //   digitizerAddress,
  //   digitizerAbi,
  //   provider
  // )
  // let ownerAddress = params.filter.ownerId
  // let transaction = digitizer.balanceOf(ownerAddress).then((result) => { 
  //   return result.toNumber()

  // })
  // const totalResult = Promise.resolve(transaction).then((result)=>{
    
  //   return result
  // })
  
 

  return {
    query: gql`
      ${queryString}
    `,
    variables: params, // params = { id: ... }
    parseResponse: (response) => {
      let responseData =
        response.data[
        pluralize(_.camelCase(raSubgraphTranslator[resourceName]))
        ];
      responseData = responseData.map(hoistData);
      return {
        data: responseData,
        // total: responseData[0]["contract"]["loanCount"],
        total: "30",
      };
    },
  };
}

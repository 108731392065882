//@ts-nocheck
import { DashboardMenuItem, Menu, MenuItemLink } from "ra-ui-materialui";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import LocalAtmSharpIcon from "@mui/icons-material/LocalAtmSharp";
import { makeStyles } from "@material-ui/core/styles";
import DashboardIcon from '@mui/icons-material/Dashboard';

const useStyles = makeStyles({
    fullMenu: {
        marginTop: "35%",
        padding: "5px",
        overflowWrap: "normal"
    },
    item: {
        color: "#ffffff",
        overflow: "hidden"
    },
    icon: {
        color: "#ffffff"
    }
})


export const MyMenu = (props) => {
    const classes = useStyles();
    return (
        <Menu className={classes.fullMenu} {...props} >
            <MenuItemLink className={classes.item} to="/" primaryText="Dashboard" leftIcon={<DashboardIcon className={classes.icon} />} />
            <MenuItemLink className={classes.item} to="/loan" primaryText="My Loans" leftIcon={<AccountCircleIcon className={classes.icon} />} />
            <MenuItemLink className={classes.item} to="/homogenizedLoans" primaryText="Homogenized Loans" leftIcon={<LocalAtmSharpIcon className={classes.icon} />} />
            <MenuItemLink className={classes.item} to="/homogenizer" primaryText="Homogenizers" leftIcon={<AutoAwesomeMotionIcon className={classes.icon} />} />
        </Menu>)
}
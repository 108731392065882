import { chainIdMap } from "../constants";
import { Contract } from "@ethersproject/contracts";
import { useActiveWeb3React } from "../utils";
import { Digitizer } from "../typechain/Digitizer";
import { IERC20 } from "../typechain/IERC20";
import { ContractInterface, ethers } from "ethers";
import { HomogenizerFactoryClone } from "../typechain/HomogenizerFactoryClone";

export default () => {
  const { account, library, chainId } = useActiveWeb3React();
  // @ts-ignore
  const networkName = chainIdMap[chainId];
  // todo why doesn't deploymentsPath work here
  const homogenizerAbi = require("../deployments/" +
    networkName +
    "/HomogenizerUpgradable.json").abi as ContractInterface;
  const Digitizer = require("../deployments/" +
    networkName +
    "/Digitizer.json");
  const HomogenizerFactoryClone = require("../deployments/" +
    networkName +
    "/HomogenizerFactoryClone.json");
  const MockUSDC = require("../deployments/" + networkName + "/MockUSDC.json");
  const signer = library.getSigner(account);
  const digitizer = new Contract(
    Digitizer.address,
    Digitizer.abi,
    signer
  ) as Digitizer;
  const homogenizerFactory = new Contract(
    HomogenizerFactoryClone.address,
    HomogenizerFactoryClone.abi,
    signer
  ) as HomogenizerFactoryClone;
  const stablecoin = new Contract(
    MockUSDC.address,
    MockUSDC.abi,
    signer
  ) as IERC20;
  return { signer, digitizer, homogenizerFactory, stablecoin, homogenizerAbi };
};

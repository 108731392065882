export const apiUrl = "https://api.thegraph.com/subgraphs/name/pandichef/hpni0";
// export const deploymentsPath = "../../pandifi-v1-core-subtree/deployments/";
export const chainIdMap = {
  "1": "mainnet",
  "3": "ropsten",
  "4": "rinkeby",
  "5": "goerli",
  "42": "kovan",
  "31337": "localhost",
};

//@ts-nocheck
import detectEthereumProvider from "@metamask/detect-provider";
import { getAddress } from "@ethersproject/address";
import { Contract } from "@ethersproject/contracts";
import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";
import { AddressZero } from "@ethersproject/constants";
import { useWeb3React as useWeb3ReactCore } from "@web3-react/core";
import { ethers } from "ethers";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/styles";

export const generateApiUrl = (chainId: string) => {
  let link;
  if (chainId == "0x3") {
    // ropsten
    link = "https://api.thegraph.com/subgraphs/name/pandichef/hpni0";
  } else if (chainId == "0x7a69") {
    // localhost
    link = "http://127.0.0.1:8000/subgraphs/name/pandifi";
  }
  return link;
};

export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

// account is not optional
export function getSigner(
  library: Web3Provider,
  account: string
): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

export function getContract(
  address: string,
  ABI: any,
  library: Web3Provider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as any
  );
}

export const isMetaMaskInstalled = async () => {
  //Have to check the ethereum binding on the window object to see if it's installed
  const { ethereum } = window;
  let value = await detectEthereumProvider();

  // return Boolean(ethereum && ethereum.isMetaMask && value === ethereum);
  return Boolean(ethereum && value === ethereum);
};

export const getConnectedAccount = async () => {
  try {
    const { ethereum } = window;
    //@ts-ignore
    const accounts = await ethereum.request({ method: "eth_accounts" });
    //@ts-ignore
    const chainId = await ethereum.request({ method: "eth_chainId" });
    return [accounts, chainId];
  } catch (error) {
    console.log(error);
  }
};

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> {
  const context = useWeb3ReactCore<Web3Provider>();
  // const contextNetwork = useWeb3ReactCore<Web3Provider>('NETWORK')
  // return context.active ? context : contextNetwork
  return context.active ? context : null;
}

export function toBigNumber(n: number): BigNumber {
  // Source: https://github.com/Uniswap/uniswap-v3-core/blob/main/test/shared/utilities.ts
  return ethers.utils.parseUnits(n.toString());
}

export function toBigNumberWithDecimal(n: number, decimals: number = 6): BigNumber {
  // Source: https://github.com/Uniswap/uniswap-v3-core/blob/main/test/shared/utilities.ts
  return ethers.utils.parseUnits(n.toString(), decimals)
  // return BigNumber.from(n.toString() + '0'.repeat(18))
}

export const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "red",
    color: "black",
    maxWidth: 220,
    fontSize: "1em",
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});


export function loanDataFactory(index: number): any {
  let originalUPB = [110000, 115000, 125000, 165000, 195000][index]
  console.log(originalUPB)
  let UPBAtIssuance = originalUPB - 10000
  let currentUPB = UPBAtIssuance

  return {
    // dates i.e., unix timestamp in SECONDS (date +%s in bash)
    dateAtIssuance: 1623523734,
    // floatish
    UPBAtIssuance: toBigNumberWithDecimal(UPBAtIssuance),
    // accumulatedCashFlow: toBigNumber(0),
    // string
    offChainDataURI: 'https://www.nytimes.com',
    lastUpdate: 1623523734,
    // priorDelinquency: false, // i.e., prevent homogenization
    original: {
      // dates i.e., unix timestamp in SECONDS (date +%s in bash)
      originationDate: 1623523734,
      firstPaymentDate: 1623523734,
      // enumish i.e., enum without cap
      firstTimeHomebuyer: 0,
      channel: 1,
      originator: 1,
      purpose: 1,
      propertyType: 1,
      occupancy: 1,
      propertyCountryCode: 1,
      propertyStateFIPS: 1,
      propertyCountyFIPS: 1,
      amortizationType: 1,
      // enum
      // censusTractIncomeLevel: 1,
      // borrowerIncomeLevel: 1,
      // integer
      numberOfBorrowers: 2,
      numberOfUnits: 1,
      creditScore: 761,
      term: 360,
      // floatish
      rate: toBigNumberWithDecimal([2.75, 2.0, 2.5, 3, 3.25][index]),
      UPB: toBigNumberWithDecimal(originalUPB),
      LTV: toBigNumberWithDecimal(80),
      CLTV: toBigNumberWithDecimal(80),
      DTI: toBigNumberWithDecimal(43),
      mortgageInsurancePercentage: toBigNumberWithDecimal(0),
      // llpaPercentage: toBigNumber(1.5),
      llpaPercentage: toBigNumberWithDecimal([1, 1.5, 2, 2.5, 3][index]),
      // boolean
      // highBalanceLoanIndicator: false,
      // agencyEligible: true,
      // new
      // mipct: 0,
      propertyMSACode: 0,
      propertyPostalCode: 0,
      propertyCensusTract: 0,
      prepaymentPenaltyIndicator: 0,
      interestOnlyTerm: 0,
      borrowerIncomeLevel: 0,
      censusTractIncomeLevel: 0,
      purposeSubcategory: 0,
      // selfEmploymentFlag: 0,
      armResetTerm: 0,
      armMargin: 0,
      armIndex: 0,
      armCapStructure: 0,
      balloonTerm: 0,
      program: 1,
      product: 1,
      incomeVerificationLevel: 1,
      mortgageInsuranceType: 1,
      documentationType: 1,
      thirdPartyReviewer: 1,
      thirdPartyReviewDate: 1623523734,
      // thirdPartyReviewGrade: 0,
      thirdPartyReviewCreditGrade: 0,
      thirdPartyReviewComplianceGrade: 0,
      thirdPartyReviewValuationGrade: 0,
      lienPosition: 1,
    },
    current: {
      payHistory: ethers.utils.toUtf8Bytes('^^^^PXXXXXXXXXXXXXXXXXXXXXXXXXXX'),
      UPB: toBigNumberWithDecimal(currentUPB),
      // delinquencyStatus: 1, // servicing just indicates a delinquency
      firstDelinquencyDate: 0,
      // zeroBalanceCode: 0,
      // modificationFlag: false,
      firstModificationDate: 0,
      // llpaPercentage: toBigNumber([1, 1.5, 2, 2.5, 3][index]),
      // servicingFee: toBigNumber(10),
      servicingRate: toBigNumberWithDecimal(0.1),
      rate: toBigNumberWithDecimal([2.75, 2.0, 2.5, 3, 3.25][index]),
      // deferredUPB: 0,
      servicer: 1,
      creditScore: 761,
      // lastCreditScoreUpdate: 1623523734,
      rateAccrualType: 0,
      servicingType: 1,
    },
  }
}

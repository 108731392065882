// @ts-nocheck
import { Fragment, useState, useEffect } from "react";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import { LoanList } from "./pages/LoanList";
import { HomogenizedLoans } from "./pages/HomogenizedLoans";
import { HomogenizerList } from "./pages/HomogenizerList";
import {
  Admin,
  Resource,
  defaultTheme
} from "react-admin";

import MyLayout from "./themes/MyLayout";
import buildGraphQLProvider from "ra-data-graphql";
import buildQuery from "./buildQuery";
import { useWeb3React } from "@web3-react/core";
import { formatEther, parseEther } from "@ethersproject/units";
import { InjectedConnector } from "@web3-react/injected-connector";

import { createTheme } from "@mui/material";
import { useStyles } from "./Styles";
import { myTheme } from "./themes/myTheme";
import { ThemeProvider } from "@material-ui/styles";
import { useListContext } from "ra-core";
import { useGetMany } from "ra-core";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { generateApiUrl } from "./utils";
import { isMetaMaskInstalled } from "./utils";
import { useActiveWeb3React } from "./utils/index";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import LocalAtmSharpIcon from "@mui/icons-material/LocalAtmSharp";
import { Pending } from "@mui/icons-material";
import { PendingContext } from "./PendingContext";
export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 31337],
});


// Note: this is a required for logoutButton prop to work
const emptyAuthProvider = {
  // authentication
  login: (params) => Promise.resolve(),
  checkError: (error) => Promise.resolve(),
  checkAuth: (params) => Promise.resolve(),
  logout: () => Promise.resolve(),
  getIdentity: () => Promise.resolve(),
  // authorization
  getPermissions: (params) => Promise.resolve(),
};

function App() {
  const [pendingStatus, setPendingStatus] = useState(false)
  const { account, chainId, activate } = useWeb3React();
  let apiUrl = generateApiUrl(chainId);
  useEffect(() => {
    if (!account) {
      activate(injected);
    }
    isMetaMaskInstalled().then((metamaskStatus) => {
      setMetaMaskStatus(metamaskStatus);
    });
  }, []);

  const [dataProvider, setDataProvider] = useState(null);
  const [metaMaskStatus, setMetaMaskStatus] = useState(false);
  useEffect(() => {
    console.log(apiUrl);
    if (apiUrl) {
      const link = createHttpLink({ uri: apiUrl, fetch: fetch as any });
      const cache = new InMemoryCache({ addTypename: false });
      const apolloClient = new ApolloClient({ link, cache });
      // console.log(link)
      // console.log(cache)
      // console.log(apolloClient)
      buildGraphQLProvider({
        // introspection: { schema },
        // clientOptions: { uri: uri },
        client: apolloClient,
        buildQuery: buildQuery,
      }).then((dataProvider: any) => {
        console.log(dataProvider)
        setDataProvider(() => dataProvider);
      });
    }
  }, [apiUrl]);

  // console.log(dataProvider);
  if (!metaMaskStatus) {
    return (
      <div className="loader-container">
        <div className="loader">Please download metamask and reload...</div>
      </div>
    );
  } else {
    if (dataProvider)
      return (
        <PendingContext.Provider value={{ pendingStatus, setPendingStatus }}>

          <ThemeProvider theme={myTheme}>
            <Admin
              layout={MyLayout}
              dataProvider={dataProvider}
              authProvider={emptyAuthProvider}
              loginPage={false}
              logoutButton={false}
              dashboard={Dashboard}
            >
              <Resource
                name="loan"
                icon={AccountCircleIcon}
                list={LoanList}
                options={{ label: "My Loans" }}
              />
              <Resource
                name="homogenizedLoans"
                icon={LocalAtmSharpIcon}
                list={HomogenizedLoans}
                options={{ label: "Homogenized Loans" }}
              />
              <Resource
                name="homogenizer"
                icon={AutoAwesomeMotionIcon}
                list={HomogenizerList}
                options={{ label: "All Homogenizers" }}
              />
            </Admin>
          </ThemeProvider>
        </PendingContext.Provider>
      );
    else {
      return (
        <div className="loader-container">
          <div className="loader">
            Loading... Please make sure Ropsten network or localhost network is
            connected in Metamask
          </div>
        </div>
      );
    }
  }
}

export default App;

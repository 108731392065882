//@ts-nocheck
import * as React from "react";
import { Layout } from "react-admin";
import Header from "../components/Header";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import {
  // Menu,
  Notification,
  Sidebar,
  setSidebarVisibility,
  ComponentPropType,
} from "react-admin";
import { MyMenu } from "../components/Menu";

//root background initially set to #1c394f
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    backgroundColor: "white",
    position: "relative",
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
  },
  contentWithSidebar: {
    marginTop: "75px",
    display: "flex",
    flexGrow: 1,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    padding: theme.spacing(3),
    marginTop: "4em",
    paddingLeft: "4em",
  },
  sideContent: {
    background: "#315565",

  },
  noSideContent: {
    display: "none",
  },
}));

//@ts-ignore
const MyLayout = ({ children, dashboard }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  //@ts-ignore
  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  useEffect(() => {
    dispatch(setSidebarVisibility(true));
  }, [setSidebarVisibility]);

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <Header />
        <main className={classes.contentWithSidebar}>
          <Sidebar className={classes.sideContent}>
            <MyMenu hasDashboard={dashboard} />
          </Sidebar>
          <div className={classes.content}>{children}</div>
        </main>
        <Notification />
      </div>
    </div>)
};

MyLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  dashboard: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  title: PropTypes.string.isRequired,
};

export default MyLayout;

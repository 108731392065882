import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Web3Provider as EthersWeb3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from "@apollo/client";
import { apiUrl } from "./constants";



// Web3ReactProvider is what provides the React "context" to be able
// to use "const context = useWeb3React()"
// This is where you choose which Ethereum connection library to use
// i.e., ethers or web3js

// const apiUrl = "https://api.thegraph.com/subgraphs/name/pandichef/hpni0";
// const client = new ApolloClient({
//   uri: apiUrl,
//   cache: new InMemoryCache(),
// });

function getLibrary(provider: any) {
  const library = new EthersWeb3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    {/* <ApolloProvider client={client}> */}
    <App />
    {/* </ApolloProvider> */}
  </Web3ReactProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { makeStyles } from "@material-ui/core";

// these are the listview styles
export const useStyles = makeStyles({
  table: {
    borderSpacing: "5px",
    background: "white",
    border: "solid black 2px",
    padding: "2px",
    borderRadius: "5px",
    marginTop: "3em"
  },
  thead: {
    overflowY: "auto",
    overflowX: "hidden",
    "&:checkbox": {
      color: "white",
      background: "white",
    },
  },
  tbody: {
    overflowY: "scroll",
    overflowX: "hidden",
  },
  row: {
    height: "3em",
    borderBottom: "1px solid black",
    "&:last-child": {
      borderBottom: "0",
    },
    "&:hover": {
      backgroundColor: "#686868",
    },
  },
  rowCell: {
    borderSpacing: "3px",
    border: "1px solid black",
  },
  rowEven: {
    backgroundColor: "#f2f2f2",
    "&:hover": {
      backgroundColor: "#686868",
    },
  },
  headerCell: {
    backgroundColor: "#3b444b",
    color: "white",
    border: "1px solid white",
  },
  button: {
    background: "#f2f2f2",
    fontSize: "90%",
    height: "4vh",
    width: "20vw",
    marginBottom: "10px",
    marginTop: "3em",
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow: "0px 0px 15px 2px #1c394f",
    '&:hover': {
      backgroundColor: '#1c394f',
      color: '#ffffff',
    }
  },
  mockBalance:{
    width: "50%",
    display: "flex",
    height:"100px",
    alignItems: "baseline"
  }
});

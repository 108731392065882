//@ts-nocheck
import { useRecordContext } from "react-admin";
import { usdFormatter } from "../utils";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@material-ui/core";
import {
  propertyTypeMappingArray,
  purposeMappingArray,
  occupancyMappingArray,
} from "../ra-utils";

export const BuyButton = () => {
  const record = useRecordContext();
  const bestHomogenizerAddress = record["bestHomogenizerId"];
  // console.log(record["bestHomogenizerId"])
  let urlLink =
    "https://app.uniswap.org/#/swap?inputCurrency=0x6ee856ae55b6e1a249f04cd3b947141bc146273c&outputCurrency=" +
    bestHomogenizerAddress;
  return (
    <div>
      <a href={urlLink} target="_blank">
        Buy
      </a>
    </div>
  );
};
export const SellButton = () => {
  const record = useRecordContext();
  const bestHomogenizerAddress = record["bestHomogenizerId"];
  // console.log(record["bestHomogenizerId"])
  let urlLink = `https://app.uniswap.org/#/swap?inputCurrency=${bestHomogenizerAddress}&outputCurrency=0x6ee856ae55b6e1a249f04cd3b947141bc146273c`;
  return (
    <div>
      <a href={urlLink} target="_blank">
        Sell
      </a>
    </div>
  );
};

//@ts-ignore
export const DollarField = ({ source }) => {
  const record = useRecordContext();
  return <span>{usdFormatter.format(Number(record[source]))}</span>;
};

//@ts-ignore
export const DateField = ({ source }) => {
  const record = useRecordContext();
  let date = new Date(record[source] * 1000);
  // console.log(date)
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear();
  return <span>{month + "/" + day + "/" + year}</span>;
};

//to-do: make sure correct decimals are coming from the subgraph balances
//@ts-ignore
export const ActiveUserHomogenizerUPBDollarField = ({ source }) => {
  // console.log("source " + source + " is of tyype " + typeof source)
  const record = useRecordContext();
  let activeAddress = source.toLowerCase();
  const balances = record.balances;
  const contractAddress = record.id;

  let activeBalance = balances.filter(
    (entry) => entry.id === contractAddress + "/" + activeAddress
  );
  // console.log("activeBalance[0]: ", activeBalance[0])
  if (activeBalance.length > 0) {
    // console.log("activeBalance[0].value: ", activeBalance[0].value)
    let myBalance = activeBalance[0].value;
    return <span>{usdFormatter.format(myBalance)}</span>;
  } else return <span>{usdFormatter.format(0)}</span>;
};

//@ts-ignore
export const PercentageField = ({ source }) => {
  const record = useRecordContext();
  // console.log(source, record[source])
  return <span>{Number(record[source]).toFixed(3)}%</span>;
};

//@ts-ignore
export const PropertyType = () => {
  const record = useRecordContext();
  console.log(record);
  let propTypeNumber = record["propertyType"];
  let propType = propertyTypeMappingArray[propTypeNumber];
  return <span>{propType}</span>;
};
//@ts-ignore
export const PurposeType = () => {
  const record = useRecordContext();
  let purposeTypeNumber = record["purpose"];
  let purpose = purposeMappingArray[purposeTypeNumber];
  return <span>{purpose}</span>;
};
//@ts-ignore
export const OccupancyType = () => {
  const record = useRecordContext();
  let occupancyTypeNumber = record["occupancy"];
  let occupancy = occupancyMappingArray[occupancyTypeNumber];
  return <span>{occupancy}</span>;
};

//@ts-ignore
export const BuyDownPercentageField = ({ source }) => {
  const record = useRecordContext();
  // console.log("****************record**************")
  // console.log(source + " is " + record[source] + " of type " + (typeof record[source]))
  // console.log("****************record**************")
  return <span>{Number(record[source]).toFixed(3)}%</span>;
};

//@ts-ignore
export const BestHomogenizerField = () => {
  const record = useRecordContext();
  return (
    <span>
      <a href={record["bestHomogenizerSymbolLink"]} target="_blank">
        {record["bestHomogenizerSymbol"]}
      </a>
    </span>
  );
};

export const BestLoanPrice = () => {
  const record = useRecordContext();
  // console.log(record)
  return (
    <span>
      {Number(
        record["bestPriceNet"] -
          record["llpaPercentage"] -
          record["buydownPercentage"]
      ).toFixed(3)}
      %
    </span>
  );
};

//@ts-ignore
const BestLiquidityPoolPriceField = ({ source }) => {
  const record = useRecordContext();
  // console.log(typeof record[source])
  // console.log(record)
  return (
    <span>
      <a href={record["bestPriceLink"]} target="_blank">
        {Number(record[source]).toFixed(3)}%
      </a>
    </span>
  );
};

BestLiquidityPoolPriceField.defaultProps = {
  label: "Best LP Price",
  source: "bestPriceNet",
};
export { BestLiquidityPoolPriceField };

//@ts-ignore
const CurrentLiquidityPoolPriceField = ({ source }) => {
  const record = useRecordContext();
  const currentHomogenizerAddress = record["ownerId"];
  // console.log("current: ", currentHomogenizerAddress)
  //@ts-ignore
  const currentHomogenizer = record["eligibleHomogenizers"].find(
    (entry) => entry["homogenizer"]["token"]["id"] == currentHomogenizerAddress
  );
  // console.log(currentHomogenizer)

  // console.log(currentHomogenizer.homogenizer.token.bestPrice)
  // console.log(record)
  return (
    <span>
      {/* <a href={record["bestPriceLink"]} target="_blank"> */}
      {Number(currentHomogenizer.homogenizer.token.bestPriceNet).toFixed(3)}%
      {/* </a> */}
    </span>
  );
};

CurrentLiquidityPoolPriceField.defaultProps = {
  label: "Current LP Price",
  source: "bestPrice",
};
export { CurrentLiquidityPoolPriceField };

//@ts-ignore
export const BestLpFeeTier = ({ source }) => {
  const record = useRecordContext();
  return <span>{Number(record["bestPoolFeeTier"]) / 10 ** 4}%</span>;
};

//@ts-ignore
export const CurrentLpFeeTeir = ({ source }) => {
  const record = useRecordContext();
  return <span>{record}</span>;
};

//@ts-ignore
export const IDTextField = ({ source }) => {
  const record = useRecordContext();
  return <span>{record[source]}</span>;
};

IDTextField.defaultProps = {
  label: "ID",
  source: "identifier",
};

//to-do: change owner homogenizer name when homogenization possible for option other than the default best
export const OwnerField = () => {
  const record = useRecordContext();
  if (
    record["isOwnedByHomogenizer"] &&
    record["ownerId"] == record["bestHomogenizerId"]
  ) {
    return record["bestHomogenizerSymbol"];
  } else {
    return (
      <a href={record["ownerLink"]} target="_blank">
        {record["ownerId"].slice(0, 6)}...{record["ownerId"].slice(38, 42)}
      </a>
    );
  }
};

//reads record to display all eligible homogenizers for a loan in expanded view
export const EligibleHomogenizers = () => {
  const record = useRecordContext();
  // console.log(record);
  const homogenizerArray = record["eligibleHomogenizers"];
  const loanLlpaPercent = record["llpaPercentage"];

  return (
    <div>
      <h3>Loan is eligible for {homogenizerArray.length} homogenizers </h3>
      <Table>
        <TableRow>
          <TableCell>
            <h4>Homogenizer</h4>
          </TableCell>
          <TableCell>
            <h4>Best LP Price</h4>
          </TableCell>
          <TableCell>
            <h4>LP Fee</h4>
          </TableCell>
          <TableCell>
            <h4>Buy Down %</h4>
          </TableCell>
          <TableCell>
            <h4>Loan Price</h4>
          </TableCell>
        </TableRow>
        <TableBody>
          {
            //@ts-ignore
            homogenizerArray.map((entry) => {
              // console.log(entry)
              return (
                <>
                  <TableRow>
                    <TableCell>
                      {entry["homogenizer"]["token"]["symbol"]}
                    </TableCell>
                    <TableCell>
                      {Number(
                        entry["homogenizer"]["token"]["bestPriceNet"]
                      ).toFixed(3)}
                    </TableCell>
                    <TableCell>
                      {Number(
                        entry["homogenizer"]["token"]["bestPoolFeeTier"]
                      ) /
                        10 ** 4}
                      %
                    </TableCell>
                    <TableCell>
                      {Number(entry["buydownPercentage"]).toFixed(3)}
                    </TableCell>
                    <TableCell>
                      {Number(
                        entry["homogenizer"]["token"]["bestPriceNet"] -
                          loanLlpaPercent -
                          entry["buydownPercentage"]
                      ).toFixed(3)}
                    </TableCell>
                  </TableRow>
                </>
              );
            })
          }
        </TableBody>
      </Table>
    </div>
  );
};

//@ts-nocheck
import usePandiFiContracts from "../hooks/usePandiFiContracts";
import { useActiveWeb3React } from "../utils";
import { useEffect, useState, useContext } from "react";
import { usdFormatter } from "../utils";
import { useStyles } from "../Styles";
import { PendingContext } from "../PendingContext";

const MockUSDCBar: any = () => {
    const { pendingStatus, setPendingStatus } = useContext<any>(PendingContext);
    const { account, library, chainId } = useActiveWeb3React();
    const { stablecoin } = usePandiFiContracts()
    const [mockUsdcBalance, setMockUsdcBalance] = useState(0);
    const [totalSupply, setTotalSupply] = useState(0);
    const classes = useStyles();

    useEffect(async () => {
        const activeBalance = await stablecoin.balanceOf(account)
        setMockUsdcBalance(usdFormatter.format(Number(activeBalance)/(10**6)))
        const total = await stablecoin.totalSupply();
        setTotalSupply(usdFormatter.format(Number(total)/(10**6)))
    }, [])

    return (
        <div className={classes.mockBalance}>
            <h3>My "USDC" Balance:&nbsp; &nbsp;</h3> {mockUsdcBalance} of {totalSupply}
        </div>
    )
}
export default MockUSDCBar
// @ts-nocheck
import "../App.css";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import LinearProgress from '@mui/material/LinearProgress';
import { List, Datagrid } from "react-admin";
import { useStyles } from "../Styles";
import {
  loanExporter,
  PostBulkActionButtons,
  loanFilters,
  ExpandedData,
} from "../ra-utils";
import {
  BestHomogenizerField,
  BestLiquidityPoolPriceField,
  IDTextField,
  BestLpFeeTier,
  BestLoanPrice,
  DollarField,
  PercentageField,
  BuyDownPercentageField,
} from "../components/CustomFields";
import usePandiFiContracts from "../hooks/usePandiFiContracts";
import { useContext } from 'react'
import { PendingContext } from "../PendingContext";
// import { useForm } from 'react-hook-form';
import SendUsdcButton from "../components/SendUsdcButton";
import GenerateNFTButton from "../components/GenerateNFTButton";
import MockUSDCBar from "../components/MockUSDCBar";

const mintAsync = async (
  digitizer: Digitizer,
  signerAddress: string,
  originalData: any,
  currentData: any,
  offChainDataURI: string,
  options: any
): Promise<any> => {
  let tx = await digitizer.mint(signerAddress, [originalData], [currentData], [offChainDataURI], options)
  await tx.wait()
  console.log(`Minted token in block ${tx.blockNumber} (nonce: ${tx.nonce})`)
  return tx
}


//Custom CSS based on Datagrid api
//https://marmelab.com/react-admin/List.html#datagrid-css-api


export function LoanList(props: any) {
  // const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);
  const classThemes = useStyles();
  const { pendingStatus, setPendingStatus } = useContext(PendingContext);
  const { signer } = usePandiFiContracts();
  let propsWithBatchActions = Object.assign({}, props);
  propsWithBatchActions["batchAction"] = "homogenize";





  //to-do: Make sure useContext is compatible with multiple users on the application at once
  if (pendingStatus) {
    return (
      <div style={{ width: "75%", padding: "25px", margin: "0 auto 0 auto" }}>
        <h3>You will be redirected when all transactions clear. Please, check metamask for any pending confirmations and approvals...</h3>
        <LinearProgress />
      </div>
    )
  }
  else {
    return (
      <>
        <MockUSDCBar />
        <List
          title=" | My Loans"
          // aside={<SidebarAggregations />}
          {...propsWithBatchActions}
          exporter={loanExporter}
          bulkActionButtons={
            <PostBulkActionButtons bulkAction={propsWithBatchActions.batchAction} />
          }
          sort={{ field: 'identifier', order: 'ASC' }}
          // pagination={false}
          filters={loanFilters}
          filter={{ ownerId: signer._address.toLowerCase() }}
        >
          <Datagrid classes={classThemes} expand={<ExpandedData />} optimized>
            <IDTextField sortBy="identifier" />
            {/* <UPBTextField label="Original UPB" source="originalUPB" /> */}
            <DollarField label="Current UPB" source="currentUPB" />
            <PercentageField source="originalRate" />
            {/*Not sortable because error occurs when bestPriceNet is part of query because it isnt on the subgraph */}
            <BestLiquidityPoolPriceField sortable={false} />
            {/* LP Fee Tier does not come in as a number and is not sortable */}
            <BestLpFeeTier label="Best LP Fee" />
            <PercentageField label="LLPA" source="llpaPercentage" />
            {/*Not sortable because buydown comes in as a string */}
            <BuyDownPercentageField label="Buydown %" source="buydownPercentage" sortable={false} />
            <PercentageField label="Servicing Rate" source="servicingRate" />
            {/*Not sortable because price is calculated in the front end*/}
            <BestLoanPrice label="Best Loan Price" />
            <BestHomogenizerField label="Best Homogenizer" sortable={false} />
          </Datagrid>
        </List>
        <SendUsdcButton/>
        <GenerateNFTButton />
      </>
    );
  }

}

import { useGetMany, useListContext, useGetList } from "ra-core";
import { BestLiquidityPoolPriceField } from "../CustomFields";
import { useEffect, useState } from "react";
import usePandiFiContracts from "../../hooks/usePandiFiContracts";
import { makeStyles } from "@material-ui/core/styles";
import { usdFormatter } from "../../utils";
import { Card } from "@mui/material";
import { CardContent } from "@material-ui/core";
import { Button } from "@material-ui/core"
const useStyles = makeStyles(() => ({
  buttonFlex: {
    display: "flex",
    justifyContent: "space-evenly"
  },

  card: {
    margin: "5%"
  },
  contentArea: {
    minWidth: '30vw',
    minHeight: '30vh',
  },
  button: {
    fontSize: "70%",
    height: "4vh",
    width: "12vw",
    marginBottom: "10px",
    marginTop: "10px",
    boxShadow: "0px 0px 15px 2px #1c394f",
    '&:hover': {
      backgroundColor: '#1c394f',
      color: '#ffffff',
  },
  },
  statLine: {
    display: "inline",
    fontFamily: "source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace"

  },
  cardContent: {
    // minWidth: '50%',
    // minHeight: '50%',
    // background: "#315565",
  },
  cardHeader: {
    fontSize: "250%",
    textAlign: "center",
    fontFamily: "source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace"
  },
  text: {
    fontSize: "120%"
  }
}));

export default function AggregateStats() {
  const classes = useStyles();

  // The following data is pulled from the blockchain to be displayed on the app dashboard:
  // - total Supply of all digitized loans
  // - UPB sum of all digitized loans
  // - the number of homogenizers deployed for use
  // - UPB sum of all homogenized loans
  const [totalSupply, setTotalSupply] = useState<number | null>(() => null);
  const [totalUPB, setTotalUPB] = useState<number | null>(() => null);
  const [totalHomogenizers, setTotalHomogenizers] = useState<number | null>(() => null)
  const [totalHomogenizerUPB, setTotalHomogenizerUPB] = useState<string | null>(() => null)
  const { signer, digitizer, stablecoin, homogenizerAbi } =
    usePandiFiContracts();

  // useGetList returns an object of all homogenizers, which is used to count homogenizers
  // and upb sum of homogenizers
  const { data, loading, error } = useGetList("homogenizer");
  //to-do: use correct async inside of useEffect
  //@ts-ignore
  useEffect(async () => {
    let digitizerSupply = await digitizer.totalSupply();
    setTotalSupply(Number(digitizerSupply));
    let digitizerTotalUpb = await digitizer.totalUPB();
    //@ts-ignore
    setTotalUPB(usdFormatter.format(Number(digitizerTotalUpb) / 10 ** 6));
  }, []);
  useEffect(() => {
    //number of homogenizers is the size of the object length
    const homogenizerNumber = Object.keys(data).length;
    setTotalHomogenizers(homogenizerNumber)
    let totalUPB = 0
    Object.entries(data).forEach(([key, value]) => {
      totalUPB += value.notional
    })
    setTotalHomogenizerUPB(usdFormatter.format(totalUPB))
    //run this useEffect() when the data is pulled by the query
  }, [data])

  if (totalSupply) {
    return (
      <div className={classes.contentArea}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <h2 className={classes.cardHeader}>Aggregate Loan Statistics</h2>
            <div className={classes.text}>
              {" "}
              <h5 className={classes.statLine}>Total Loans: </h5>
              {totalSupply}
            </div>
            <div className={classes.text}>
              {" "}
              <h5 className={classes.statLine}>Total UPB: </h5>
              {totalUPB}
            </div>
            <div className={classes.text}>
              {" "}
              <h5 className={classes.statLine}>Homogenizers in use: </h5>
              {totalHomogenizers}
            </div>
            <div className={classes.text}>
              {" "}
              <h5 className={classes.statLine}>Homogenized UPB: </h5>
              {totalHomogenizerUPB}
            </div>
          </CardContent>
        </Card>
        <div className={classes.buttonFlex}>
          <Button variant="outlined" className={classes.button} onClick={()=>{window.location.href = "/#/homogenizedLoans"}}>
            Browse Loans
          </Button>
          <Button variant="outlined" className={classes.button} onClick={()=>{window.location.href = "/#/loan"}}>
            Homogenize My Loans
          </Button>
        </div>
      </div>);
  } else return <h1> Loading Statistics...</h1>;
}

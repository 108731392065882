import usePandiFiContracts from "../hooks/usePandiFiContracts";
import { Button } from "@material-ui/core"
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useState, useContext } from "react";
import { useStyles } from "../Styles";
import { Digitizer } from "../typechain/Digitizer";
import { loanDataFactory } from "../utils";
import { ethers } from "ethers"
import { PendingContext } from "../PendingContext";

function SimpleDialog(props: any) {
    const { onClose, open, selectedValue } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value: any) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Set backup account</DialogTitle>
        </Dialog>
    )
}

// SimpleDialog.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
//   selectedValue: PropTypes.string.isRequired,
// };

//function based on pandifi-v1-core script
const mintAsync = async (
    digitizer: Digitizer,
    signerAddress: string,
    originalData: any,
    currentData: any,
    offChainDataURI: string
): Promise<any> => {
    //@ts-ignore
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    //TEMPORARY ADMIN PRIVATE KEY USED FOR ROPSTEN DEMO SITE
    const mintAdmin = new ethers.Wallet('4b4dc16204ceba609bfddaaab4c89d6a16d004c818f3d9d778d7316304e79a9b', provider)
    console.log(signerAddress)
    //@ts-ignore
    let tx = await digitizer.connect(mintAdmin).mint(signerAddress, [originalData], [currentData], [offChainDataURI])
    let waitforit = await provider.waitForTransaction(tx.hash)
    window.location.reload();
    return tx
}



const GenerateNFTButton = () => {
    const { pendingStatus, setPendingStatus } = useContext<any>(PendingContext);
    const { signer, digitizer } = usePandiFiContracts();
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("0");
    const classes = useStyles();
    const handleClickOpen = async () => {
        setOpen(true);
        setPendingStatus(true)

        let randomLoanGenerator = Math.floor(Math.random() * 5);
        let mintPromise = await mintAsync(
            digitizer,
            signer._address,
            loanDataFactory(randomLoanGenerator).original,
            loanDataFactory(randomLoanGenerator).current,
            loanDataFactory(randomLoanGenerator).offChainDataURI,
        )
    };

    const handleClose = (value: any) => {
        setOpen(false);
        setSelectedValue(value);
        console.log(value)
    };

    return (
        <>
            <Button variant="outlined" className={classes.button} onClick={handleClickOpen}>Generate Demo Loan</Button>
            {/* <SimpleDialog
                onClose={handleClose}
                open={open}
                selectedValue={selectedValue}
            /> */}
        </>
    )
}

export default GenerateNFTButton
import { AppBar } from "react-admin";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import logo from "../images/pandifiLogo.png";
import logo2 from "../images/pandifi_logo.png";
import { shadows } from '@mui/system';
import { blueGrey } from "@material-ui/core/colors";
import { useActiveWeb3React } from "../utils";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { couldStartTrivia } from "typescript";
import { Tooltip } from "@material-ui/core";
import { chainIdMap } from "../constants";
import { useWeb3React } from "@web3-react/core";
const useStyles = makeStyles({
  fullHeader: {
    boxShadow: "0px 0px 55px 15px #315565",
    display: "flex",
    color: 'black'
  },
  imgContainer: {
    // position: 'absolute',
    // left: '98%',
    // top: '75%',
    // transform: 'translate(-50%, -50%)'
  },
  logoImg: {
    paddingTop: "1em",
    paddingBottom: "1em",
    textAlign: "left",
    margin: 'auto',
    marginLeft: '25px',
  },
  title: {
    flex: 1,
    marginTop: ".25em",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: "white",
    fontSize:"200%",
    weight: "300",
    fontFamily: "source-code-pro,Menlo,Monaco,Consolas,'Courier New',monospace",
  },
  spacer: {
    flex: 1,
  },
  buttons: {
    background: "#1c394f",
    borderRadius: "10px",
    color: "#ffffff"
  },
  button: {
    color: "#ffffff",
    "&:hover": {
      backgroundColor: '#d0cfcc',
      color: "#000000"
    }
  },
  networkButton: {
    background: "#315565",
    borderRadius: "15px",
    paddingRight: "1.5em",
    marginRight: "-1em",
    color: "white"
  },
  addressButton: {
    background: "#000000",
    borderRadius: "15px",
    color: "white",
    padding: "5px 10px 5px 10px",
    marginRight: "25px",
    "&:hover": {
      backgroundColor: '#d0cfcc',
      color: "#000000"
    }
  },
});
//@ts-ignore
const Header = (props) => {

  const [connectedChain, setConnectedChain] = useState<string>(() => "");
  const { account, library, chainId } = useActiveWeb3React();
  let accountDisplay: string = account.slice(0, 6) + "..." + account.slice(39, 42)

  const classes = useStyles();
  //@ts-ignore
  useEffect(() => {
    if (chainId == "0x1") {
      setConnectedChain("Mainnet")
      alert("Please connect to one of the following networks use PandiFi:" + "\r\n" + "Ropsten" + "\r\n" + "Local Network")
    }
    else if (chainId == "0x3") {
      setConnectedChain("Ropsten");
    }
    else if (chainId == "0x4") {
      setConnectedChain("Rinkeby")
      alert("Please connect to one of the following networks use PandiFi:" + "\r\n" + "Ropsten" + "\r\n" + "Local Network")
    }
    else if (chainId == "0x5") {
      setConnectedChain("Goerli")
      alert("Please connect to one of the following networks use PandiFi:" + "\r\n" + "Ropsten" + "\r\n" + "Local Network")
    }
    else if (chainId == "0x2a") {
      setConnectedChain("Kovan")
      alert("Please connect to one of the following networks use PandiFi:" + "\r\n" + "Ropsten" + "\r\n" + "Local Network")
    }
    else if (chainId == "0x7a69") {
      setConnectedChain("Local Network")
    }

    //@ts-ignore
    const { ethereum } = window;
    //whenever accounts or network changes, simply reload to dashboard
    //@ts-ignore
    ethereum.on('chainChanged', (chainId: string) => {
      window.location.href = "/"
    });
    //@ts-ignore
    ethereum.on('accountsChanged', (account) => {
      window.location.href = "/"

    });
  }, [])
  return (
    <AppBar showMenuIconButton={false} className={classes.fullHeader} {...props}>
            <div className={classes.imgContainer}>
        <a href="/"><img className={classes.logoImg} src={logo2} width="50px" height="50px"></img></a>
      </div>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      >
        PandiFi
      </Typography>
      <span className={classes.spacer} />
      <Button className={classes.networkButton}>{connectedChain}</Button>
      {/* <Tooltip title="Click to copy"> */}
      <Button className={classes.addressButton}>{accountDisplay}</Button>
      {/* <Button className={classes.addressButton} onClick={() => {
          navigator.clipboard.writeText(account);
          alert('copied active address')
        }}>{accountDisplay}</Button> */}
      {/* </Tooltip> */}
      <ButtonGroup
        color="primary"
        aria-label="outlined secondary button group"
        className={classes.buttons}
      >
        <div >
          <Button className={classes.button}
            onClick={() => {
              window.open("https://p537khd5ikumi8-docs.pandi.fi/");
            }}
          >
            Read Docs
          </Button>
          <Button className={classes.button}
            onClick={() => {
              window.open("https://github.com/pandifi");
            }}
          >
            Github Repo
          </Button>
        </div>
      </ButtonGroup>
    </AppBar>
  );
};

export default Header;

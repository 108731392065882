//@ts-nocheck
import { Fragment, useState, useContext } from "react";
import {
  Button,
  Confirm,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from "react-admin";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
// import { useHomogenizeSelected } from "./hooks";
import { BigNumber, Signer } from "ethers";
import { toBigNumber, getConnectedAccount, useActiveWeb3React } from "../utils";
import { useGetMany } from "react-admin";
import usePandiFiContracts from "../hooks/usePandiFiContracts";
import { Digitizer } from "../typechain/Digitizer";
import { HomogenizerFactoryClone } from "../typechain/HomogenizerFactoryClone";
import { PendingContext } from "../PendingContext";
import { confirmationTable } from "../ra-utils/index"

async function dehomogenizeBatch(
  loanList: Record<string, any>[],
  signer: Signer,
  digitizer: Digitizer,
  homogenizerFactory: HomogenizerFactoryClone,
  stablecoin: IERC20,
  homogenizerAbi: ContractInterface
) {
  const signerAddress: string = await signer.getAddress();
  // console.log("loanlist ", loanList);
  //why is this undefined sometimes?
  if (loanList) {
    let tokenIds: Array<string> = loanList.map((loan) => loan.identifier);
    let homogenizerAddresses: Array<string> = loanList.map(
      (loan) => loan["ownerId"]
    );
    let eligibleForDehomogenization: Array<boolean> = loanList.map((loan) => {
      if (loan["isOwnedByHomogenizer"]) return true;
      else return false;
    });
    let uniqueHomogenizerAddresses: Set<any> = new Set(homogenizerAddresses);
    let uniqueHomogenizerArray: Array<string> = Array.from(
      uniqueHomogenizerAddresses
    );
    if (!eligibleForDehomogenization.includes(false)) {
      let setApprovalForAll;
      let completedDigitizerApproval;
      let stablecoinAllowance;
      let setStablecoinAllowance;
      let completedStableCoinApproval;

      let approvalChecks = uniqueHomogenizerArray.map(
        async (homogenizerAddress: string) => {
          //first, we check to see if each homogenizer has permission to transfer
          //digitizer ERC721 tokens on the the signer's behalf.
          let digitizerApproval = await digitizer.isApprovedForAll(
            signerAddress,
            homogenizerAddress
          );
          console.log(digitizerApproval)
          if (digitizerApproval == false) {
            try {
              setApprovalForAll = await digitizer
                .connect(signer)
                .setApprovalForAll(homogenizerAddress, true);
            }
            catch (error) {
              window.location.reload();
            }

            completedDigitizerApproval = await setApprovalForAll.wait();
            //to-do: test if the next line is superfluous
            // if (completedDigitizerApproval.status) {
            //   // console.log("digitizer approval complete");
            // }
          }
          //Test if homogenizer has permission to transfer ERC20 stablecoin tokens on signers behalf
          stablecoinAllowance = Number(
            await stablecoin.allowance(signerAddress, homogenizerAddress)
          );
          //If homogenizer's stablecoin allowance is insufficient, set it very high
          if (stablecoinAllowance < 10 ** 15) {
            let amount = BigNumber.from("10000000000000000000000000000000000");
            try {
              setStablecoinAllowance = await stablecoin
                .connect(signer)
                .approve(homogenizerAddress, amount);
              completedStableCoinApproval = await setStablecoinAllowance.wait();
            } catch (error) {
              window.location.reload();
            }
          }
        }
      );
      //Perform batch operation only after all async operations inside of the approvalChecks are complete
      Promise.all(approvalChecks).then(async () => {
        try {
          let batchOperation = await homogenizerFactory.dehomogenizeBatch(
            tokenIds,
            homogenizerAddresses,
            true
          );
          let batchCompletion = await batchOperation.wait();
          batchCompletion ? (window.location.href = "/") : console.log("not yet");
        } catch (error) {
          window.location.reload();
        }
      });
    }
    else {
      let firstIneligibleLoan =
        tokenIds[eligibleForDehomogenization.indexOf(false)];
      alert(
        "Loan #" + firstIneligibleLoan + " is not eligible for homogenization"
      );
    }
  }
}


//to-do determine where to check for ownership
const DehomogenizeButton = ({ resource, selectedIds }: any) => {
  const { pendingStatus, setPendingStatus } = useContext(PendingContext);

  // console.log(selectedIds)
  const { signer, digitizer, homogenizerFactory, stablecoin, homogenizerAbi } =
    usePandiFiContracts();
  const { account, library, chainId } = useActiveWeb3React();
  const { data, loading, error } = useGetMany(resource, selectedIds);
  // console.log("data ", data);
  const [open, setOpen] = useState(false);
  // const refresh = useRefresh();
  // const notify = useNotify();
  const handleClick = () => {
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);

  return (
    <Fragment>
      <Button
        label="Dehomogenize Selected"
        icon={<MonetizationOnIcon />}
        onClick={handleClick}
      />
      <Confirm
        fullWidth
        maxWidth="xl"
        isOpen={open}
        loading={loading}
        title={
          "DeHomogenization: Please look through the data below to ensure you are homogenizing the correct NFTS"
        }
        //to-do: insert table for dehomogenize overview
        content={data ? confirmationTable(data, chainId) : null}
        onConfirm={() => {
          setPendingStatus(true)
          dehomogenizeBatch(
            data,
            signer,
            digitizer,
            homogenizerFactory,
            stablecoin,
            homogenizerAbi
          );
          setOpen(false);

        }}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default DehomogenizeButton;

//@ts-nocheck
//ra-utils are used in React Admin components throughout Pandifi
import jsonExport from "jsonexport/dist";
import { downloadCSV, useGetList, TextField, TextInput, NumberInput, FilterList, FilterListItem } from "react-admin";
import HomogenizeButton from "../components/HomogenizeButton";
import DehomogenizeButton from "../components/DehomogenizeButton";
import { Fragment } from "react";
import { EligibleHomogenizers, DollarField, PercentageField, DateField, PropertyType, PurposeType, OccupancyType } from "../components/CustomFields";
import { Button, Toolbar } from "ra-ui-materialui";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {
  List,
  Pagination as RaPagination,
  PaginationActions as RaPaginationActions,
} from 'react-admin';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter
} from "@material-ui/core";
import { chainIdMap } from "../constants";
import { usdFormatter } from "../utils";
import usePandiFiContracts from "../hooks/usePandiFiContracts";
// import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { MapsHomeWork, AttachMoney, PersonOutline } from "@mui/icons-material";
import { Card, CardContent, withStyles } from '@material-ui/core';

//exporter defines which cells are extracted and downloaded by the export button
//NOTE: currently, it is restricted to 1,000 records. If you want to increase or
//decrease this limit, pass a maxResults prop to the <ExportButton> in a custom <ListActions> component as shown here: https://marmelab.com/react-admin/List.html#exporter
export const loanExporter = (posts) => {
  console.log(posts);
  const loansForExport = posts.map((entry) => {
    console.log(entry)
    const { contract, eligibleHomogenizers, hoisted, transfers, ...fieldsForExport } =
      entry; // omit contract, eligibleHomogenizers, hoisted, and transfers cells from export & return only desired fields
    return fieldsForExport;
  });
  console.log(loansForExport);
  jsonExport(loansForExport, (err, csv) => {
    console.log(err);
    downloadCSV(csv, "PandiFi_Loans_Export");
  });
};

//special exporter to download a list of all homogenizers displayed on the "All Homogenizers" page
export const homogenizerExporter = (posts) => {
  console.log(posts);
  const homogenizersForExport = posts.map((entry) => {
    const { balances, ...fieldsForExport } =
      entry; // omit eligibleHomogenizers, hoisted, and transfers cells from export
    // postForExport.author_name = post.author.name; // add a field
    return fieldsForExport;
  });
  console.log(homogenizersForExport);
  jsonExport(homogenizersForExport, (err, csv) => {
    console.log(err);
    downloadCSV(csv, "PandiFi_Homogenizers_Export");
  });
};

//Bulk actions displayed depend on the page being displayed.
export const PostBulkActionButtons = (props) => {
  const { bulkAction, ...filteredProps } = props;
  if (props.bulkAction == "homogenize") {
    return (
      <Fragment>
        {/* <BulkExportButton {...filteredProps} label="Export Selected" /> */}
        <HomogenizeButton {...filteredProps} />
      </Fragment>
    );
  } else if (props.bulkAction == "dehomogenize") {
    return (
      <Fragment>
        {/* <BulkExportButton {...filteredProps} label="Export Selected" /> */}
        <DehomogenizeButton {...filteredProps} />
      </Fragment>
    );
  }
};

//Enables special filtering for lists
//Currently, loanFilters is used in Homogenized Loans List as a link from Homogenizer List onclick event
export const loanFilters = [
  <NumberInput label="Homogenizer Address" source="ownerId" />,
  <NumberInput
    label="Min Current UPB"
    source="currentUPB_gte"
    defaultValue=""
    alwaysOn
  />,
  <NumberInput
    label="Max Current UPB"
    source="currentUPB_lte"
    defaultValue=""
    alwaysOn
  />,
  <NumberInput
    label="Min Original UPB"
    source="originalUPB_gte"
    defaultValue=""
    alwaysOn
  />,
  <NumberInput
    label="Max Original UPB"
    source="originalUPB_lte"
    defaultValue=""
    alwaysOn
  />,
  <NumberInput
    label="Min Original Credit Score"
    source="originalCreditScore_gte"
    defaultValue=""
    alwaysOn
  />,
  <NumberInput
    label="Max Original Credit Score"
    source="originalCreditScore_lte"
    defaultValue=""
    alwaysOn
  />,
  <NumberInput
    label="Min Combined LTV"
    source="CLTV_gte"
    defaultValue=""
    alwaysOn
  />,
  <NumberInput
    label="Max Combined LTV"
    source="CLTV_lte"
    defaultValue=""
    alwaysOn
  />,
  <NumberInput
    label="Min Original Rate"
    source="originalRate_gte"
    defaultValue=""
    alwaysOn
  />,
  <NumberInput
    label="Max Original Rate"
    source="originalRate_lte"
    defaultValue=""
    alwaysOn
  />,
  <NumberInput
    label="Min Servicing Rate"
    source="servicingRate_gte"
    defaultValue=""
    alwaysOn
  />,
  <NumberInput
    label="Max Servicing Rate"
    source="servicingRate_lte"
    defaultValue=""
    alwaysOn
  />,

];


//Expanded Data displays all statistics shown in clickable row expansion
// data is pulled from Custom Fields file
export const ExpandedData = () => {
  return (
    <div
      style={{
        background: "#D0D0D0",
        fontFamily: "Montserrat",
        padding: "15px",
        borderRadius: "10px",
        borderStyle: "solid",
        whiteSpace: "pre-wrap",
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <div style={{ width: "65%", padding: "0px 10px 10px 10px" }}>
        <EligibleHomogenizers />
      </div>
      <div style={{ width: "35%", padding: "0px 10px 10px 10px" }}>
        <h3>Loan Statistics</h3>
        Origination Date: <DateField label="Origination Date" source="originationDate" />
        <br></br>
        Original UPB: <DollarField label="Current UPB" source="originalUPB" />
        <br></br>
        Original Credit Score:{" "}
        <TextField label="Original Credit Score" source="originalCreditScore" />
        <br></br>
        Servicing Rate:{" "}
        <PercentageField label="Servicing Rate" source="servicingRate" />
        <br></br>
        LTV:{" "}
        <PercentageField label="Loan-to-Value" source="LTV" />
        <br></br>
        Combined LTV:{" "}
        <PercentageField label="Loan-to-Value" source="CLTV" />
        <br></br>
        DTI:{" "}
        <PercentageField label="Debt-to-Income" source="DTI" />
        <br></br>
        Lien Position:{" "}
        <TextField label="Lien Position" source="lienPosition" />
        <br></br>
        Term:{" "}
        <TextField label="Term" source="term" />
        <br></br>
        Property Type: {" "}
        <PropertyType />
        <br></br>
        Purpose: {" "}
        <PurposeType />
        <br></br>
        Occupancy: {" "}
        <OccupancyType />
      </div>
    </div>
  );
};

//filter used for custom views according to Occupancy type
export const PurposeFilter = () => (
  <FilterList label="Purpose" icon={<AttachMoney />}>
    <FilterListItem label="Unknown" value={{ purpose: 0 }} />
    <FilterListItem label="Cash Out Refinance" value={{ purpose: 1 }} />
    <FilterListItem label="Rate/Term Refinance" value={{ purpose: 2 }} />
    <FilterListItem label="Purchase" value={{ purpose: 3 }} />
  </FilterList>
)

//to-do: Align filter definitons with up to date mapping
export const PropertyFilter = () => (
  <FilterList label="Property Type" icon={<MapsHomeWork />}>
    <FilterListItem label="Condomiunium" value={{ propertyType: 0 }} />
    <FilterListItem label="Co-Operative" value={{ propertyType: 1 }} />
    <FilterListItem label="Planned Urban Development" value={{ propertyType: 2 }} />
    <FilterListItem label="manufactured Home" value={{ propertyType: 3 }} />
    <FilterListItem label="Single Family Home" value={{ propertyType: 4 }} />
  </FilterList>
)

export const purposeMappingArray = [
  "Unknown",
  "Cash Out: Debt Consolidation—Proceeds used to pay off existing loans other than loans secured by real estate.",
  "Cash Out: Home Improvement/Renovation",
  "Cash Out: Other/Multipurpose/Unknown purpose",
  "Limited Cash-Out (GSE Definition)",
  "Facilitate REO (Repo Financing for Manufactured Housing)",
  "First Time Home Purchase i.e., purchaser has not owned a principal residence in the past three years",
  "Other-than-first-time Home Purchase",
  "Rate/Term Refinance (Any)",
  "Rate/Term Refinance -- Borrower initiated",
  "Construction-to-Permanent",
  "Assumption"
]

export const propertyTypeMappingArray = [
  "Unknown",
  "Single Family Detached (non-PUD)",
  "Co-op",
  "Condo, Low Rise (4 or fewer stories)",
  "Condo, High Rise (5+ stories)",
  "Condotel (as defined in Issuer’s Underwriting Guidelines)",
  "dPUD (PUD with “de minimus” monthly HOA dues)",
  "PUD (Only for use with Single-Family Detached Homes with PUD riders)",
  "Townhouse (Do not report as “PUD”)",
  "Single-wide Manufactured Housing",
  "Double-wide Manufactured Housing",
  "Multi-wide Manufactured Housing",
  "1 Family Attached",
  "2 Family",
  "3 Family",
  "4 Family",
  "Anchored Retail",
  "Healthcare",
  "Hotel",
  "Industrial",
  "Land",
  "Mixed Use",
  "Multi w/ Minor Retail",
  "Office",
  "Regional Mall",
  "School",
  "Unanchored Retail",
  "Warehouse",
  "5-10 Unit Multi-Family",
  "11-20 Unit Multi-Family",
  "21-40 Unit Multi-Family",
  "41-100 Unit Multi-Family",
  "100+ Unit Multi-Family",
  "Gas Station",
  "Parking Lot",
  "Religious Congregation Building"
]

//filter used for custom views according to Occupancy type
export const OccupancyFilter = () => (
  <FilterList label="Occupancy" icon={<PersonOutline />}>
    <FilterListItem label="Unknown" value={{ occupancy: 0 }} />
    <FilterListItem label="Owner Occupied" value={{ occupancy: 1 }} />
    <FilterListItem label="Second Home" value={{ occupancy: 2 }} />
    <FilterListItem label="Investment Property" value={{ occupancy: 3 }} />
  </FilterList>

)

export const QuickFilters = () => (
  <div style={{ background: "white", margin: "15px", padding: "10px", border: "3px solid black", borderRadius: "10px" }}>
    <HomogenizedLoansSidebar />
    <h3>Quick Filters</h3>
    <OccupancyFilter />
    <PurposeFilter />
    <PropertyFilter />
  </div>
)


//mapping array used for interpreting the value of "occupancy" from the subgraph
export const occupancyMappingArray = [
  "Unknown",
  "Owner Occupied",
  "Second Home",
  "Investment Property"
]
//to-do: make sure data fields are desired and correct
//Table displays all information about the selected loans to be homogenized or dehomogenized
export const confirmationTable = (data: Record<string, any>, chainId: string) => {
  // @ts-ignore
  const networkName: string = chainIdMap[chainId];
  const url = "https://" + networkName + "etherscan.io/address/";
  // @ts-ignore
  return (
    <Table>
      <TableHead>
        <TableCell colSpan={5} style={{ textAlign: "center" }}>
          <h1>Selected PandiFi NFTS</h1>
        </TableCell>
        <TableRow>
          <TableCell>NFT ID</TableCell>
          <TableCell>Current UPB</TableCell>
          <TableCell>Original Rate</TableCell>
          <TableCell>Best Homogenizer Price</TableCell>
          <TableCell>Homogenizer Symbol</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row: any) => (
          <TableRow>
            <TableCell>{row["identifier"]}</TableCell>
            <TableCell>
              {usdFormatter.format(row["currentUPB"])}
            </TableCell>
            <TableCell>{Number(row["originalRate"])}%</TableCell>
            <TableCell>
              {Number(
                row["bestPriceNet"] -
                row["llpaPercentage"] -
                row["buydownPercentage"]
              ).toFixed(3)}%
            </TableCell>
            <TableCell>
              <a href={url + row["bestHomogenizerId"]} target="_blank">
                {row["bestHomogenizerSymbol"]}
              </a>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

//Sidebar displays the homogenizer balances for the active user
export function HomogenizedLoansSidebar() {
  const { signer } = usePandiFiContracts();
  let activeAddress = signer._address.toLowerCase();
  const { data } = useGetList("homogenizer");
  if (data) {
    let arrayOfActiveUserBalances = []
    //iterate through the homogenizer query data to identify balances matching the active user's address
    Object.entries(data).forEach(([key, value]) => {
      let contractAddress = key
      let contractName = value.symbol
      //balances will contain values for all addresses that own homogenizer tokens
      let balances = value.balances
      //filter searches for the balances that match the active user's address
      let activeBalance = balances.filter(entry => entry.id == contractAddress + "/" + activeAddress)
      if (activeBalance.length > 0) {
        //since only the active user's balance is contained in the array, we take the 0 index of activeBalance
        let balance = activeBalance[0]["value"]
        let balanceObject = {}
        balanceObject[contractName] = balance
        arrayOfActiveUserBalances.push(balanceObject)
      }
    })
    // console.log(arrayOfActiveUserBalances)
    if (arrayOfActiveUserBalances.length > 0) {
      return (
        <div>
          <h2>Active User Balances</h2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Homogenizer</TableCell>
                <TableCell>User Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                arrayOfActiveUserBalances.map(row =>
                  <TableRow>
                    <TableCell>{Object.keys(row)}</TableCell>
                    <TableCell>{Object.values(row)[0]}</TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan="2">
                  Click on 'buy' to purchase additonal homogenizer tokens on uniswap.
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </div>

      )
    }
    else {
      return (<div style={{ background: "white", margin: "15px", padding: "10px", border: "3px solid black", borderRadius: "10px" }}>
        <h4>Active user has no homogenizer tokens.  Click on buy to purchase through uniswap.</h4>
      </div>
      )
    }

  }
  else {
    return (<div style={{ display: "none" }}></div>)
  }

}







//Sidebar not yet functional
// //should these variables be in useState?
// let upbSum = 0;
// let selectedHomogenizers = {};
// function SidebarAggregations() {
//   // console.log("Aside");
//   const { selectedIds } = useListContext();
//   // const { data, loading, error } = useGetMany("ERC721Token", selectedIds);
//   const { data, loading, error } = useGetMany("loan", selectedIds);
//   console.log(selectedIds);
//   if (selectedIds.length > 0) {
//     if (loading) return <h1>Loading</h1>;
//     // if (error) return <h1>{error}</h1>;
//     else {
//       if (data) {
//         // console.log(data)
//         for (let i = 0; i < data.length; i++) {
//           upbSum = upbSum + Number(data[i]["currentUPB"]) / 10 ** 18;
//           if (!(data[i]["bestHomogenizerSymbol"] in selectedHomogenizers)) {
//             selectedHomogenizers[data[i]["bestHomogenizerSymbol"]] =
//               Math.round(Number(data[i]["currentUPB"]) / 10 ** 16) / 100;
//             console.log(Number(data[i]["currentUPB"]) / 10 ** 18);
//           } else {
//             selectedHomogenizers[data[i]["bestHomogenizerSymbol"]] =
//               selectedHomogenizers[data[i]["bestHomogenizerSymbol"]] +
//               Math.round(Number(data[i]["currentUPB"]) / 10 ** 16) / 100;
//           }
//           // console.log(selectedHomogenizers);
//         }
//       }
//       return (
//         <div
//           style={{
//             width: 200,
//             margin: "1em",
//             background: "#D0D0D0",
//             fontFamily: "Montserrat",
//             padding: "5px",
//             borderRadius: "10px",
//             borderStyle: "solid",
//             whiteSpace: "pre-wrap",
//           }}
//         >
//           <h3>
//             <b>Selected NFTs</b>
//           </h3>
//           <br />
//           <i>
//             <b>
//               <h7>Total UPB</h7>
//             </b>
//           </i>
//           <br />
//           {upbSum != 0 ? usdFormatter.format(upbSum) : null}
//           <br />
//           <br />
//           <i>
//             <b>
//               <h7>Homogenizers Selected</h7>
//             </b>
//           </i>
//           <br />
//           {/* {selectedHomogenizers.map((row) => row + "\n")} */}
//           <br />
//         </div>
//       );
//     }
//   } else {
//     return <div style={{ display: "none" }}></div>;
//   }
//   // if (data) {
//   //   return (
//   //     <div style={{ width: 200, margin: "1em" }}>
//   //       <h6>Selected Stats</h6>
//   //       {console.log(
//   //         data.reduce((a, b) => ({ currentUPB: a.currentUPB + b.currentUPB }))
//   //       )}
//   //     </div>
//   //   );
//   // } else {
//   //   return console.log("no data");
//   // }
// }
// @ts-nocheck
import gql from "graphql-tag";
// import buildGraphQLProvider from 'ra-data-graphql'
import _ from "lodash";
// const myj = require("./pandifi-v1-core-subtree/deployments/ropsten/Digitizer.json");
import { fetchLoans } from "./fetchers/loan";
import { fetchHomogenizers } from "./fetchers/homogenizer";
import { raSubgraphTranslator } from "./fetchers/utils";

const pluralize = require("pluralize");

export default (introspectionResults) => (raFetchType, resourceName, params) => {

  // console.log("params: ", params);
  // console.log("name: ", resourceName);
  // console.log("fetch type: ", raFetchType);

  // Filter out the current resource
  const resource = introspectionResults.types.find(
    (r) => r.name === raSubgraphTranslator[resourceName]
  );
  // console.log("resource", resource);
  const fields = resource.fields.map((item) => {
    // if (["LIST", "NON_NULL"].includes(item.type.kind) && item.name != "id") {
    if (
      item.type.ofType == null ||
      item.name == "id" ||
      item.name == "identifier" ||
      item.name == "ownerId"
      
    ) {
      return item.name;
    } 
    else if(item.name == "contract"){
      return("contract{loanCount}")
    }
    else {
      return item.name + " { id }";
    }
  });

  // console.log(params)
  // console.log(raFetchType)
  // console.log(resourceName)
  switch (resourceName + "." + raFetchType) {
    case "loan.GET_LIST":
      return fetchLoans(params, resourceName, fields);
      break;
    case "homogenizer.GET_LIST":
      return fetchHomogenizers(params, resourceName, fields); // todo: change to homogenizers
      break;
    case "homogenizedLoans.GET_LIST":
      return fetchLoans(params, resourceName, fields);
      break;
    //temporary filler to avoid parseResponse Error
    case "loan.GET_MANY":
      return fetchLoans(params, resourceName, fields);
      break;
    //temporary filler to avoid parseResponse Error
    case "homogenizedLoans.GET_MANY":
      return fetchLoans(params, resourceName, fields);
      break;

  }
};
